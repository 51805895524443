import { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  BorderRadius,
  breakpoints,
  buttonTemplate,
  colors,
  GridWrapper,
  imageLoaderResponsive,
  MediaImage,
  mediaQueries,
} from '@/theme/lib';
import Donate, { DonateParagraphContentType } from '../Donate';
import Image from 'next/image';
import { useMobile } from '@lib/hooks/useMobile';
import { useTablet } from '@lib/hooks/useTablet';
import { getLanguage } from '@/lib/config';

type StyledBlockProps = {
  'background-image': boolean;
};

const StyledBlock = styled.div<StyledBlockProps>`
  display: flex;
  ${(props) => BorderRadius(props.theme.config.componentRadius, '8')}
  box-sizing: border-box;
  width: 100%;
  max-width: 1528px;
  background-color: ${(props) => props.theme.colors.primary};
  min-height: 435px;
  position: relative;
  color: ${(props) =>
    props['background-image']
      ? props.theme.colors.promo.withBackground.text
      : props.theme.colors.promo.withoutBackground.text};
  overflow: hidden;

  img.pattern-texture-e,
  img.pattern-texture-g {
    display: none;
    position: absolute;

    width: 228px;
    height: 228px;
    left: calc(50% - 228px / 2);
    bottom: -138px;
    ${mediaQueries.md} {
      width: 626px;
      height: 626px;
      left: calc(50% - 626px / 2 + 585px);
      top: -214px;
    }
  }

  img.pattern-texture-e {
    display: ${(props) =>
      !props['background-image'] && props.theme.key === 'london' ? 'block' : 'none'};
  }
  img.pattern-texture-g {
    display: ${(props) =>
      !props['background-image'] && props.theme.key === 'whipsnade' ? 'block' : 'none'};
  }

  &.circle-visible--left {
    &::before {
      display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
      background-image: url(/PromoBlock/circle-left--top.svg);
      content: '';
      position: absolute;
      height: 99px;
      width: 196px;
      top: -10px;
      transform: rotate(18deg);
      right: 40px;
      z-index: 2;
    }
    &::after {
      display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
      background-image: url(/PromoBlock/circle-left--bottom.svg);
      content: '';
      position: absolute;
      height: 275px;
      width: 152px;
      bottom: 17px;
      transform: rotate(-13deg);
      left: -51px;
      z-index: 2;
    }
  }

  &.circle-visible--right {
    &::before {
      display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
      background-image: url(/PromoBlock/circle-right--top.svg);
      content: '';
      position: absolute;
      height: 105px;
      width: 196px;
      transform: rotate(-16deg);
      top: -22px;
      left: 37px;
      z-index: 2;
    }
    &::after {
      display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
      background-image: url(/PromoBlock/circle-right--bottom.svg);
      content: '';
      position: absolute;
      height: 208px;
      width: 471px;
      bottom: -52px;
      left: 160px;
      transform: rotate(-25deg);
      z-index: 2;
    }
  }

  div {
    background-color: transparent;
  }

  .image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .shadow-block {
    align-self: center;
    box-sizing: border-box;
    padding: ${(props) =>
      props['background-image']
        ? props.theme.key === 'zsl'
          ? '6.8rem 1.1rem'
          : '11.225rem 1.1rem 2.375rem'
        : '6.8rem 1.1rem'};
    width: 100%;
    &:after {
      display: ${(props) =>
        props['background-image'] && props.theme.key !== 'zsl' ? 'block' : 'none'};
      content: '';
      position: absolute;
      width: 1007.82px;
      left: -355px;
      top: 21.3%;
      bottom: -40.3%;
      background-blend-mode: multiply;
      mix-blend-mode: multiply;
      filter: blur(39.622px);
    }
  }

  .radial-shadow {
    z-index: 1;
  }

  .wrapper-block {
    padding: 0;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 2;
  }

  .title-block {
    font-family: ${(props) => props.theme.fonts.h1};
    font-size: 2rem;
    line-height: 2.1rem;
    font-weight: 600;
    text-transform: ${(props) => (props.theme.key === 'zsl' ? 'uppercase' : 'none')};
  }

  .description-block {
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .cta-block {
    margin-top: 0.5rem;
  }

  .btn-primary {
    ${buttonTemplate}
    color: ${(props) =>
      props['background-image']
        ? props.theme.colors.promo.withBackground.button
        : props.theme.colors.promo.withoutBackground.button};
    background-color: ${(props) =>
      props['background-image'] ? props.theme.colors.primary : colors.white};
    line-height: 1.2rem;
    font-size: 1rem;
    font-weight: 400;

    &:hover {
      text-decoration: none;
      border-color: ${(props) => props.theme.colors.button.primary.focusBorder};
    }

    ${mediaQueries.md} {
      line-height: 1.35rem;
      font-size: 1.125rem;
    }

    ${mediaQueries.lg} {
      line-height: 1rem;
    }
  }

  ${mediaQueries.md} {
    ${(props) => BorderRadius(props.theme.config.featureCardWrapperBorderRadius, '6')}
    min-height: 600px;

    .title-block {
      font-size: 3rem;
      line-height: 3.3rem;
    }

    .description-block {
      margin-top: 1rem;
      font-size: 1.125rem;
      line-height: 1.63125rem;
    }

    .shadow-block {
      padding: 5.5rem 0.75rem;
      max-width: 986px;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
      }
    }

    .radial-shadow {
      ${(props) =>
        props['background-image'] && props.theme.key === 'zsl' ? '' : 'background: none;'}
    }

    .wrapper-block {
      padding: ${(props) => (props.theme.key === 'zsl' ? '6rem 9rem' : '6rem 0')};
      margin: ${(props) => (props.theme.key === 'zsl' ? '0' : 'auto')};
      max-width: 592px;
      text-align: left;
    }

    &.circle-visible--left {
      &::before {
        height: 203px;
        width: 196px;
        top: -70px;
        transform: rotate(16deg);
        left: 390px;
      }
      &::after {
        background-position: top right;
        height: 281px;
        width: 355px;
        bottom: -120px;
        left: 50px;
        transform: none;
      }
    }

    &.circle-visible--right {
      &::before {
        z-index: 2;
        height: 225px;
        width: 196px;
        transform: rotate(-8deg);
        top: -77px;
        left: 155px;
      }
      &::after {
        height: 225px;
        width: 473px;
        bottom: -64;
        left: 188px;
        transform: none;
      }
    }

    .cta-block {
      margin-top: 1rem;
    }
  }
`;

interface PromoBlockProps {
  promoTitle: string;
  backgroundImage?: MediaImage | null;
  circleMotifPosition: string;
  description: {
    processed: string;
  } | null;
  cta: {
    title: string;
    url: string;
    target?: string | null;
  } | null;
  donationBlock: {
    content: DonateParagraphContentType | null;
  } | null;
}

export const StyledGradientBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3) !important;
`;

export const StyledDonationBlock = styled(StyledBlock)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 280px;

  ${mediaQueries.md} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-height: 400px;
  }

  ${mediaQueries.lg} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-height: 600px;
  }

  .wrapper-block {
    ${mediaQueries.lg} {
      padding: 4rem 3rem;
      max-width: 45%;
    }
  }

  &.circle-visible--left {
    &::before {
      display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
      background-image: url(/PromoBlock/circle-left--top.svg);
      content: '';
      position: absolute;
      height: 99px;
      width: 196px;
      top: -20px;
      transform: rotate(33deg);
      right: 40px;
      z-index: 2;
    }
    &::after {
      display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
      background-image: url(/PromoBlock/circle-left--bottom.svg);
      content: '';
      position: absolute;
      height: 275px;
      width: 152px;
      bottom: 0;
      transform: rotate(334deg);
      left: -51px;
      z-index: 2;
    }
  }

  &.circle-visible--right {
    &::before {
      display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
      background-image: url(/PromoBlock/circle-right--top.svg);
      content: '';
      position: absolute;
      height: 105px;
      width: 196px;
      transform: rotate(352deg);
      top: -20px;
      left: 37px;
      z-index: 2;
    }
    &::after {
      display: ${(props) => (props.theme.key === 'zsl' ? 'block' : 'none')};
      background-image: url(/PromoBlock/circle-right--bottom.svg);
      content: '';
      position: absolute;
      height: 208px;
      width: 471px;
      bottom: -130px;
      left: 0;
      transform: rotate(4deg);
      z-index: 2;
    }
  }

  ${mediaQueries.md} {
    &.circle-visible--left {
      &::before {
        height: 203px;
        width: 196px;
        top: -60px;
        transform: rotate(16deg);
        left: 260px;
      }
      &::after {
        background-position: top right;
        height: 281px;
        width: 355px;
        bottom: -120px;
        left: 50px;
        transform: none;
      }
    }

    &.circle-visible--right {
      &::before {
        z-index: 2;
        height: 225px;
        width: 196px;
        transform: none;
        top: -77px;
        left: 155px;
      }
      &::after {
        height: 225px;
        width: 473px;
        bottom: -100px;
        left: 60px;
        transform: rotate(12deg);
      }
    }
  }

  ${mediaQueries.lg} {
    &.circle-visible--left {
      &::before {
        height: 203px;
        width: 196px;
        top: -15px;
        transform: rotate(22deg);
        left: 300px;
      }
      &::after {
        background-position: top right;
        height: 281px;
        width: 355px;
        bottom: -50px;
        left: 50px;
        transform: none;
      }
    }

    &.circle-visible--right {
      &::before {
        z-index: 2;
        height: 225px;
        width: 196px;
        transform: rotate(3deg);
        top: -10px;
        left: 100px;
      }
      &::after {
        height: 225px;
        width: 473px;
        bottom: -30px;
        left: 10px;
        transform: rotate(5deg);
      }
    }
  }
`;

export const StyledDonationWrapper = styled.div`
  ${mediaQueries.lg} {
    width: 100%;
    position: absolute;
    z-index: 3;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .donate-component {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    ${mediaQueries.sm} {
      max-width: none;
    }

    ${mediaQueries.lg} {
      max-width: 50%;
      right: 0;
      background-color: ${(props) => props.theme.colors.donate.background};
      margin-left: auto;
    }
  }
`;

function DonationPromoBlock(data: PromoBlockProps): ReactElement {
  const { key } = useTheme();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const language = getLanguage();

  return (
    <GridWrapper alignment={'centre-wide'}>
      <StyledDonationBlock
        className={'circle-visible--' + data.circleMotifPosition}
        background-image={data?.backgroundImage?.url ? true : false}
      >
        {data?.backgroundImage && <StyledGradientBox />}
        {data?.backgroundImage && (
          <div className="image-wrapper">
            <Image
              src={data?.backgroundImage?.url}
              alt={''}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              sizes={`(min-width: ${breakpoints.lg}px) 75vw,
                (min-width: ${breakpoints.xl}px) 50vw,
                100vw`}
              width={isMobile ? 360 : isTablet ? 1024 : 1440}
              height={isMobile ? 435 : isTablet ? 768 : 600}
              loader={imageLoaderResponsive(
                isMobile ? 360 : isTablet ? 1024 : 1440,
                isMobile ? 435 : isTablet ? 768 : 600,
                true
              )}
            />
          </div>
        )}
        <div className={`shadow-block ${data?.backgroundImage?.url ? 'radial-shadow' : ''}`}>
          <div className="wrapper-block">
            <div className="title-block" lang={language !== 'en' ? language : undefined}>{data.promoTitle}</div>
          </div>
        </div>
        {key === 'london' && (
          <img loading="lazy" alt="" className="pattern-texture-e" src="/PatternTextureE.svg" />
        )}
        {key === 'whipsnade' && (
          <img loading="lazy" alt="" className="pattern-texture-g" src="/PatternTextureG.svg" />
        )}
        {data.donationBlock && !isTablet && (
          <StyledDonationWrapper>
            <Donate {...data.donationBlock} referrer={'donation-promo'} />
          </StyledDonationWrapper>
        )}
      </StyledDonationBlock>
      {data.donationBlock && isTablet && (
        <StyledDonationWrapper>
          <Donate {...data.donationBlock} referrer={'donation-promo'} />
        </StyledDonationWrapper>
      )}
    </GridWrapper>
  );
}

export default function PromoBlock(data: PromoBlockProps): ReactElement {
  const { key } = useTheme();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const language = getLanguage();

  if (data.donationBlock) return <DonationPromoBlock {...data} />;

  return (
    <GridWrapper alignment={'centre-wide'}>
      <StyledBlock
        className={'circle-visible--' + data.circleMotifPosition}
        background-image={data?.backgroundImage?.url ? true : false}
      >
        {data?.backgroundImage && <StyledGradientBox />}
        {data?.backgroundImage && (
          <div className="image-wrapper">
            <Image
              src={data?.backgroundImage?.url}
              alt={''}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              sizes={`(min-width: ${breakpoints.lg}px) 75vw,
                (min-width: ${breakpoints.xl}px) 50vw,
                100vw`}
              width={isMobile ? 360 : isTablet ? 1024 : 1440}
              height={isMobile ? 435 : isTablet ? 768 : 600}
              loader={imageLoaderResponsive(
                isMobile ? 360 : isTablet ? 1024 : 1440,
                isMobile ? 435 : isTablet ? 768 : 600,
                true
              )}
            />
          </div>
        )}
        <div className={`shadow-block ${data?.backgroundImage?.url ? 'radial-shadow' : ''}`}>
          <div className="wrapper-block">
            <div className="title-block" lang={language !== 'en' ? language : undefined}>{data.promoTitle}</div>
            {data.description && (
              <div
                className="description-block"
                dangerouslySetInnerHTML={{ __html: data.description.processed }}
                lang={language !== 'en' ? language : undefined}
              />
            )}
            {data.cta && (
              <div className="cta-block">
                <a
                  href={data.cta.url}
                  target={data.cta.target ? data.cta.target : undefined}
                  className="btn btn-primary"
                  lang={language !== 'en' ? language : undefined}
                >
                  {data.cta.title}
                </a>
              </div>
            )}
          </div>
        </div>
        {key === 'london' && (
          <img loading="lazy" alt="" className="pattern-texture-e" src="/PatternTextureE.svg" />
        )}
        {key === 'whipsnade' && (
          <img loading="lazy" alt="" className="pattern-texture-g" src="/PatternTextureG.svg" />
        )}
      </StyledBlock>
    </GridWrapper>
  );
}
